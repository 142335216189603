<template id="call-to-action">
    <div class="text">
        <div class="left-section">
            <p class="big-text">Scopri il tuo posizionamento marketing nel mercato con <span class="logo-name">BrandRatio</span> </p>
            <p class="small-text">Capirai come conquistare un pubblico enorme e creare una comunità fantastica!</p>
        </div>
    </div>
</template>

<script>
export default {
    name : 'CallToAction',
};

</script> 
  <style lang="scss" scoped>  
    .big-text {
      font-size: 50px; 
      border: tomato;
      font-weight: 900;
      margin-bottom: 0px;
      margin-top: 130px;
      color: #fff;
    }
    .logo-name{
      margin-top: 0px;
      font-size: 50px; 
      border: tomato;
      font-weight: 900;
      color: #2e4eed;
    }
    .small-text {
      font-size: 1.25rem;
      line-height: 1.875rem;
      font-weight: 900;
      margin-top: 20px;
      color: #fff;

    }
  </style>