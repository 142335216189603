<template>
    <div class="page-layout">
      <div class="left-section">
        <div class="call-to-action">
          <CallToAction />
        </div>
        <div class="buttons">
            <div class="demo-button">
                <ButtonTemplate :button-text="'Guarda la demo'" />
            </div>
            <div v-if="auth.user" class="try-button">
              <router-link to="/product"><TryButton/></router-link>
            </div>
            <div v-if="!auth.user" class="try-button">
              <router-link :to="{ name: 'login' }"> <TryButton/></router-link>
            </div>
        </div>

        <div class="credit-card-and-reviews">
              <div class="credit-card">
                <CardDeclined class="icon"/> Carta di credito non richiesta | 
              </div>
              <div class="reviews">
                <!-- inserisci le reviews quando ci sono -->
              </div>

            </div>
      </div>

      <div class="right-section">
        <!-- Content for the right section -->
      </div>
    <div v-if="showFreeTrialForm" class="popup">
      <FreeTrialForm @closePopup="closePopup" />
    </div>
    </div>
  </template>
  
  <script>
  import CallToAction from '../components/screens/CallToAction.vue';
  import ButtonTemplate from '../components/widgets/button.vue';
  import TryButton from '../components/widgets/FreeTrialButton.vue';
  import FreeTrialForm from '../components/screens/FreeTrial.vue';
  import CardDeclined from '@/assets/Icons/CardDeclined.vue';

  import { useAuthStore } from '../stores/authStore';

  export default {
    name: 'MainPage',
    components: {
      CallToAction,
      ButtonTemplate,
      TryButton,
      FreeTrialForm,
      CardDeclined
    },
    data() {
        return {
        showFreeTrialForm: false
        };
    },
    computed: {
    auth() {
      return useAuthStore();
    }
  },
    methods: {
    showPopup() {
      this.showFreeTrialForm = true;
    },
    closePopup() {
      this.showFreeTrialForm = false;
    }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .page-layout {
    display: flex;
    background: linear-gradient(to right, #00b09b, #96c93d); 
  }
  
  .left-section {
    flex: 1;
  }
  
  .right-section {
    flex: 1;
  }
  
  .call-to-action {
    margin-left: 100px; 
    margin-top: 20px;
    text-align: left;
  }

  .buttons{
    display: flex;
    align-items: row;
    margin-left: 100px;
    margin-top: 20px
  }
  .demo-button{
    margin-right: 25px;
    --button-text-color: white
  }
  .try-button{
    --button-border-color: #007bff;
    --button-text-color: #007bff;
    --button-background-color: #fff;
    --button-background-hover-color: #00bfff8d;
  }
  .credit-card{
    margin-top: 5px;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 30px
  }
  .icon{
    height: 22px;
    width: 22px;
    margin-left: 100px;
    top: 5.5px;
  }
  </style>
  