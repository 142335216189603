<template>
  <div class="main">
    <div class="title">Results</div> 
    
    <div class="radar">
      <RadarGraph
        ref="spiderGraphRef"
        :height="400"
        :width="400"
        :numberOfDataPoints="3"
        :legend='["Community",  "Storytelling", "Experience"]'
        :dataRatingsColors="[
          'rgba(196,11,30, 0.2)',   
          'rgba(51,102,255, 0.2)',   
          'rgba(0,204,102, 0.2)'   
        ]"
        :dataRatingsGridColors="[
          'rgba(196,11,30, 0.2)',    
          'rgba(51,102,255, 0.2)',   
          'rgba(0,204,102, 0.2)'    
        ]"
      />
    </div>
    
    <div class="score-boxes">
      <div class="score-box">
        <div class="score-title">Community</div>
        <div class="score">{{ sessionstore.communityScore }}</div>
      </div>
      <div class="score-box">
        <div class="score-title">Experience</div>
        <div class="score">{{ sessionstore.experienceScore }}</div>
      </div>
      <div class="score-box">
        <div class="score-title">Storytelling</div>
        <div class="score">{{ sessionstore.storytellingScore }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onUnmounted } from 'vue';
import RadarGraph from '@/components/widgets/radar.vue';
import { useSessionStore } from '@/stores/sessionStore';

export default {
  setup() {
    const sessionstore = useSessionStore();

  onUnmounted(() => {
      sessionstore.reset();
    });

    return {
      sessionstore
    };
  },
  name: 'RadarPage',
  components: {
    RadarGraph
  }
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  max-width: 100%;
  position: relative;
}

.title {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 50px;
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.radar {
  margin-bottom: 30px;
}

.score-boxes {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 600px;
}

.score-box {
  background-color: #f9f9f9;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  width: 150px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.score-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.score {
  font-size: 24px;
  font-weight: bold;
  color: #29d;
}
</style>
