<template>
  <nav v-if="!showMetricsconstant">
      <div class="intro-text">
        Siamo al terzo passo. Ci concentriamo sull’esperienza che il tuo brand genera per i consumatori. La chiamiamo brand experience. Con questo termine intendiamo quegli elementi che completano il prodotto come il servizio clienti, la vendita online, la possibilità di personalizzazione, ecc.. 
        Come per lo storytelling il risultato è compreso tra 10 (la brand experience è al massimo) e 0 (c’è un po’ di lavoro da fare). 
      </div>
      <!-- <div class="loading-bar">
          <LoadingBar :bgcolor="'#6a1b9a'" :completed="completed" />
      </div> -->
          <ButtonTemplate v-if="sessionstore.experienceLoaded" :button-text="'Continua'" @click="showMetrics" class="con-button"/>
  </nav>
  <nav v-if="showMetricsconstant">
    <ExperienceResults :experienceScore="sessionstore.experienceScore" />
  </nav>
</template>

<script>
// import { ref } from "vue";
// import LoadingBar from '@/components/widgets/LoadingBar.vue';
import ButtonTemplate from '@/components/widgets/button.vue';
import ExperienceResults from '@/components/screens/Posizionamento/ExperienceMetrics.vue';
import  { useSessionStore } from '@/stores/sessionStore';

export default {
  setup () {
    const sessionstore = useSessionStore()

    return {
      sessionstore
    };
  },
name: "ExperienceConfirm",
components: { 
  // LoadingBar,
  ButtonTemplate,
  ExperienceResults
},
data() {
  return {
      showMetricsconstant: false,
  };
},

methods:{
  showMetrics() {
    this.showMetricsconstant = true;
    this.$emit('advance-step');
  },
  
},
};
</script>


<style lang="scss" scoped>

nav{
position: relative;
display: flex;
background: linear-gradient(to bottom, #2ecc71, #27ae60);
background-position: center;
flex-direction: column;
justify-content: center;
align-items: center; 
height: 100%; 
min-height: 100vh; 
}
.intro-text{
  position: flex;
  text-align: center;
  top: -50px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-left: 35px;
  margin-right: 35px;
}
.loading-bar{
  justify-content: center;
  display: flex;
  width: 50%;
}
.con-button{
  margin-bottom: 20px;
--button-border-color: #1f6402;
--button-text-color: #1f6402;
--button-background-color: #fff;
--button-background-hover-color: #1b560240;
&:hover{
  cursor: pointer;
}
}
</style>
