<template>
  <div class="free-trial-form" @click.self="closePopup" v-if="!showInputBar">
    <div class="finestra-pop-up" @click.stop>
      <h2>Registrazione Demo Gratuita</h2>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="company_name">Nome Azienda:</label>
          <input type="text" id="company_name" v-model="company_name" required>
        </div>
        <div class="form-group">
          <label for="companySector">Settore Azienda:</label>
          <select id="companySector" v-model="companySector" required>
            <option value="">Selezione settore</option>
            <option value="Technology">Tecnologia</option>
            <option value="Finance">Finanza</option>
            <option value="Healthcare">Consulenza</option>
          </select>
        </div>

      <router-link to="/product" class="button-invio" type="submit">Invia</router-link>

      </form>
    </div>
  </div>
  <div v-if="showInputBar" class="inputbar">

  </div>
</template>

<script>

export default {
  name: 'FreeTrialForm',
  components:{
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      company_name: '',
      companySector: '',
      showInputBar: false
    };
  },
  methods: {
    submitForm() {
      // Handle form submission here (e.g., sending data to server)
      console.log('Form submitted:', this.firstName, this.lastName, this.email, this.company_name, this.companySector);
      // You can also perform form validation before submission
    },
    closePopup() {
      this.$emit('closePopup');
    },
    showInputBarFunction() {
      this.showInputBar = true;
    },
    closeInputBar() {
      this.showInputBar = false;
    }
  }
};
</script>

<style scoped>
.free-trial-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the popup appears on top */
  }

.finestra-pop-up {
  background-color: rgba(255, 255, 255, 0.755); 
  padding: 20px;
  border-radius: 10px;
}

.form-group {
  margin-top: 10px;
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-invio {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
}

.button-invio:hover {
  background-color: #0056b3;
  text-decoration: underline;
}
</style>
