<template>
  <div>
    <FinalPage
      :ratings="[
        [6, 7, 7], 
        [8, 9, 7], 
        [5, 1, 8]  
      ]"
      :names="['ciao1', 'ciao2', 'ciao3']"
    ></FinalPage>
  </div>
</template>


<script>
import FinalPage from '@/components/screens/Posizionamento/FinalPage.vue';

export default {
  name: 'App',
  components: {
    FinalPage
  },
  data() {
    return {
      chartData: [80, 50, 70, 90, 60],
      chartLabels: ['A', 'B', 'C', 'D', 'E']
    };
  }
};
</script>

<style>
</style>
