<template>
    <div class="circle-container">
      <div class="circle" @mouseover="showCommands = true">
        <div class="initials">{{ initials }}</div>
      </div>
      <div v-if="showCommands" class="commands" @mouseover="showCommands = true" @mouseleave="hideCommands">
        <ul>
          <li><router-link :to="{ name: 'User' }">Il mio profilo</router-link></li>
          <li @click="handleCommand('My Settings')">My Settings</li>
          <li @click="handleSignOut">Log-out</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import { getAuth, signOut} from "firebase/auth"
  import router from "@/router";

  export default {
    name: 'CircleButton',
    props: {
      initials: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        showCommands: false,
      };
    },
    methods: {
      handleCommand(command) {
        this.$emit('command', command);
      },
      hideCommands() {
        this.showCommands = false;
      },
      handleSignOut() {
        const auth = getAuth();
        signOut(auth).then(() =>{
            router.push("/main")
            console.log("Utente disconesso con successo")
        })
      }
    },
  };
  </script>
  
  <style scoped>
  .circle-container {
    position: relative;
    display: inline-block;
  }
  
  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #3498db;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .initials {
    font-size: 12px;
    font-weight: bold;
  }
  
  .commands {
    position: absolute;
    top: 35px; /* Adjust this value if necessary */
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    color: #000;
  }
  
  .commands ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .commands li {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .commands li:hover {
    background-color: #f0f0f0;
  }
  </style>
  