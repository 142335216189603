<template>
    <div v-if="!ShowFinalPage && !ShowExperiencePage" class="search-results">
      <div class="title-section">
        <h2>Community Metrics</h2>
      </div>
      <div class="score-section">
        <div class="score-info">
          <p>Abbiamo calcolato il tuo score</p>
          <div class="score-bar">
            <div class="score-fill" :style="{ width: sessionstore.communityScore * 10 + '%' }"></div>
          </div>
          <div class="score-value">{{ sessionstore.communityScore }}</div>
        </div>
      </div>
      <div class="score-feedback">
        <div v-if="sessionstore.communityScore >= 0 && sessionstore.communityScore <= 5" class="feedback-text">{{ TextPlaceholders[0] }}</div>
        <div v-else-if="sessionstore.communityScore >= 6 && sessionstore.communityScore <= 8" class="feedback-text">{{ TextPlaceholders[1] }}</div>
        <div v-else class="feedback-text">{{ TextPlaceholders[2] }}</div>
      </div>
    </div>
  </template>
  
  <script>
  import { useSessionStore } from '@/stores/sessionStore';

  export default {
  setup() {
    const sessionstore = useSessionStore()

    return {
      sessionstore
    };
  },
    name: "CommunityResults",
    components: { 
  },

    data() {
    return {
      ShowFinalPage: false,
      ShowExperiencePage: false,
      TextPlaceholders: [
        "L'azienda sembra avere scarsa attenzione nei confronti della community. Non riesce a coinvolgere e interagire in modo significativo con attuali e potenziali consumatori. La presenza online è limitata e poco partecipativa, e mancano iniziative per creare un senso di appartenenza e coesione tra i membri della community. Ci sono opportunità significative da cogliere per migliorare l'engagement e la partecipazione della community",
        "L'azienda sta cercando di coinvolgere la community, ma ci sono ancora margini di miglioramento. Le interazioni con i consumatori sono presenti, ma potrebbero essere più frequenti e significative. L'azienda potrebbe sfruttare meglio gli strumenti tradizionali e online per identificare e misurare la forza della propria community. Alcune iniziative sono state intraprese, ma c'è ancora spazio per una maggiore coesione e partecipazione della community",
        "L'azienda ha costruito una community solida e coinvolgente. Le interazioni con attuali e potenziali consumatori sono frequenti e significative, creando un forte legame tra i membri della community e il brand. Sfruttando una varietà di strumenti tradizionali e online, l'azienda è in grado di misurare e promuovere attivamente la coesione della community. Eventi auto organizzati e pagine social non ufficiali sono indicatori della forza e della vitalità della community. Congratulazioni all'azienda per aver costruito una gran bella community"
      ]
    };
  },
  methods: {
    showPage() {
      this.ShowFinalPage = true;
    },
    showPreviousPage() {
      this.ShowFinalPage = false;
      this.ShowExperiencePage = true;
    },
  }
  };
  </script>
  
  <style scoped>
  .search-results {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    min-height: 100vh;
    top: 50px;
    margin-top: 50px;
  }
  
  .title-section {
    position: relative;
    left: 0px;
    margin-top: 20px;
    font-size: large;
  }
  
  .score-section {
    text-align: center;
    margin-top: 50px;
    top: 50px;
  }
  
  .score-info {
    display: flex;
    flex-direction: column;
  }
  
  .score-bar {
    width: 500px;
    height: 25px;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin: 10px auto;
    overflow: hidden;
  }
  
  .score-fill {
    height: 100%;
    background-color: #29d;
  }
  
  .score-value {
    font-size: 50px;
    font-weight: bold;
  }
  
  .metrics-section {
    margin-top: 20px;
  }
  
  .grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    margin-top: 30px;
  }
  
  .result {
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .title {
    padding: 10px;
    font-size: 13px;
    font-weight: lighter;
    text-align: left;
  }
  
  .content {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .metric {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .additional-info {
    margin-top: 20px;
  }
  
  .score-feedback {
    max-width: 70%;
    padding: 10px;
    border-radius: 15px;
    background-color: #29e176;
    text-align: center;
    margin: 0 auto; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .feedback-text {
    word-wrap: break-word;
  }
  </style>
  