<template>
    <div class="page">
      <!-- Inserire pagina Dicono di noi -->
      <FinalPage :ratings="[[4,7,3],
      [9,2,1],
      [1,9,8]
    ]" :names="['prova', 'prova3', 'prova2']"></FinalPage>
    </div>
</template>


<script>

import FinalPage from '../screens/Posizionamento/FinalPage.vue';


export default {
  name: 'AboutUs',
  components: {
    FinalPage,

  },

    data() {
    return {
      ShowExperiencePage: false,
      ShowCommunityPage: false, 
      ShowStorytellingPage: true, 
      ShowFinalPage: false,
      CurrentIndex: 1,
      ShowNavigationMenu: false
    };
  },
  methods: {
  showNavigationMenu() {
      this.ShowNavigationMenu = true
    },
  showFollowingPage() {
    if (this.CurrentIndex === 1) {
      this.ShowStorytellingPage = false;
      this.ShowCommunityPage = false;
      this.ShowExperiencePage = true;
      this.ShowFinalPage = false;
      this.CurrentIndex += 1;
      this.ShowNavigationMenu = false;
    } else if (this.CurrentIndex === 2) {
      this.ShowStorytellingPage = false;
      this.ShowCommunityPage = true;
      this.ShowExperiencePage = false;
      this.ShowFinalPage = false;
      this.CurrentIndex += 1;
      this.ShowNavigationMenu = false;
    } else if (this.CurrentIndex === 3) {
      this.ShowStorytellingPage = false;
      this.ShowCommunityPage = false;
      this.ShowExperiencePage = false;
      this.ShowFinalPage = true;
      this.CurrentIndex += 1;
      this.ShowNavigationMenu = false;
    }
  },
  showPreviousPage() {
    if (this.CurrentIndex === 1) {
      this.ShowStorytellingPage = false;
      this.ShowCommunityPage = false;
      this.ShowExperiencePage = false;
      this.ShowFinalPage = false;
      this.CurrentIndex -= 1;
      this.ShowNavigationMenu = false;
    } else if (this.CurrentIndex === 2) {
      this.ShowStorytellingPage = true;
      this.ShowCommunityPage = false;
      this.ShowExperiencePage = false;
      this.ShowFinalPage = false;
      this.CurrentIndex -= 1;
      this.ShowNavigationMenu = false;
    } else if (this.CurrentIndex === 3) {
      this.ShowStorytellingPage = false;
      this.ShowCommunityPage = false;
      this.ShowExperiencePage = true;
      this.ShowFinalPage = false;
      this.CurrentIndex -= 1;
      this.ShowNavigationMenu = false;
    }
  }
}

}
</script>

<style lang="scss" scoped>
  .page{
    position: relative;
    background: #fff;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; 
    min-height: 100vh; 
}

</style>