<template>
  <header :class="{'scrolled': isScrolled}">
    <nav class="container">
      <!-- Button to go to home page -->
      <div class="branding">
        <router-link class="header" :to="{ name: 'home' }">BrandRatio Logo</router-link>
      </div>
      <div class="nav-links" v-if="!isMobile">
        <ul>
          <li><router-link class="link" :to="{ name: 'main' }">Prodotto</router-link></li>
          <li><router-link class="link" :to="{ name: 'Sessioni' }">Sessioni</router-link></li>
          <li><router-link class="link" :to="{ name: 'casiduso' }">Casi d'uso</router-link></li>
          <li><router-link class="link" :to="{ name: 'chisiamo' }">Chi siamo</router-link></li>
          <li><router-link class="link" :to="{ name: 'pricing' }">Prezzi</router-link></li>
          <li><router-link class="link" :to="{ name: 'diconodinoi' }">Dicono di noi</router-link></li>
          <li v-if="!auth.user"><router-link class="link" :to="{ name: 'login' }">Login / Register</router-link></li>
          <li v-if="auth.user"><ProfileIcon class="profile-icon" :initials="getInitials(auth.user.email)"></ProfileIcon></li>
        </ul>
      </div>
    </nav>
    <menu-icon v-if="isMobile" @click="toggleMobileNav" class="menu-icon" />
    <transition name="mobile-nav">
      <ul class="mobile-nav" v-show="mobileNav">
        <li><router-link class="link" :to="{ name: 'main' }">Prodotto</router-link></li>
        <li><router-link class="link" :to="{ name: 'casiduso' }">Casi d'uso</router-link></li>
        <li><router-link class="link" :to="{ name: 'chisiamo' }">Chi siamo</router-link></li>
        <li><router-link class="link" :to="{ name: 'pricing' }">Prezzi</router-link></li>
        <li v-if="!auth.user"><router-link class="link" :to="{ name: 'login' }">Login / Register</router-link></li>
      </ul>
    </transition>
  </header>
</template>

<script>
import MenuIcon from './MenuLines.vue';
import ProfileIcon from '@/components/TopPages/ProfileCircle.vue';
import { useAuthStore } from '../stores/authStore';

export default {
  name: 'MainNavigation',
  components: {
    'menu-icon': MenuIcon,
    ProfileIcon,
  },
  data() {
    return {
      mobileNav: false,
      isScrolled: false,
      isMobile: false,
    };
  },
  computed: {
    auth() {
      return useAuthStore();
    }
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    checkScreenWidth() {
      this.isMobile = window.innerWidth <= 768; 
    },
    getInitials(email) {
      // Extract initials from email or use a different method to generate initials
      return email ? email[0].toUpperCase() : '';
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth(); 
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.checkScreenWidth);
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff00;
  padding: 0 25px;
  z-index: 99;

  &.scrolled {
    background-color: #ffffff;
    transition: background-color 0.5s ease-in-out;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.06);

    .link {
      color: #000;
    }
  }

  .link {
    font-weight: 500;
    padding: 0 10px;
    transition: 0.3s color ease;
    color: #fff;

    &:hover {
      color: #1eb8b3;
    }
  }

  nav {
    display: flex;
    align-items: center;
    padding: 25px 0;

    .branding {
      display: flex;
      align-items: center;
      font-size: 24px;
      margin-left: 5%;

      .header {
        font-weight: 600;
        color: #000;
        text-decoration: none;
      }
    }

    .nav-links {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;

      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: flex;
          align-items: center;
          margin-right: 32px;

          &:last-child {
            margin-right: 0;
          }

          .profile-icon {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .mobile-nav {
    padding: 20px;
    width: 70%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    background-color: #3e455e;
    top: 0;
    left: 0;

    .link {
      padding: 10px 0;
      color: white;
    }
  }

  .mobile-nav-enter-active,
  .mobile-nav-leave-active {
    transition: all 1s ease;
  }

  .mobile-nav-enter {
    transform: translateX(-250px);
  }

  .mobile-nav-enter-to {
    transform: translateX(0);
  }

  .mobile-nav-leave-to {
    transform: translateX(-250px);
  }
}
</style>
