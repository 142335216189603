<template>
    <div class="body">
        <div class="elements">
            <div class="top">
                <div class="name" :style="{ color: nameColor }">
                    {{ userPlanName }}
                </div>
                <div class="label">
                    {{ price }}€ utente/mese
                </div>
            </div>
            <div class="mid">
                <div class="subscribe">
                    <button
                        class="subscribe-button"
                        :style="{ backgroundColor: nameColor }"
                        @mouseover="hovered = true"
                        @mouseleave="hovered = false"
                        @click="handleSubscribeClick"
                    >
                        Iscriviti!
                    </button>
                </div>
            </div>
            <div class="features">
                <div class="data-item">
                    <p><strong>Placeholder text to be filled</strong></p>
                </div>
                <div class="divider"></div>
                <div class="data-item">
                    <p><strong>Placeholder text to be filled</strong></p>
                </div>
                <div class="divider"></div>
                <div class="data-item">
                    <p><strong>Placeholder text to be filled</strong></p>
                </div>
                <div class="divider"></div>
                <div class="data-item">
                    <p><strong>Placeholder text to be filled</strong></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BasicUser',
    props: {
        userPlanName: {
            type: String,
            default: 'Utente Basic',
        },
        nameColor: {
            type: String,
            default: 'rgb(109, 199, 248)',
        },
        price: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            hovered: false,
        };
    },
    methods: {
        handleSubscribeClick() {
            // Placeholder function for button click
            console.log('Subscribe button clicked!');
            // Add your functionality here
        },
        darkenColor(color, amount) {
            // Simple function to darken the RGB color
            const colorParts = color.match(/\d+/g).map(Number);
            const [r, g, b] = colorParts.map(part => Math.max(part - amount, 0));
            return `rgb(${r}, ${g}, ${b})`;
        },
    },
    computed: {
        buttonHoverColor() {
            return this.hovered ? this.darkenColor(this.nameColor, 30) : this.nameColor;
        },
    },
};
</script>

<style lang="scss" scoped>
.body {
    border: 1px solid #ccc;
    background-color: white;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 350px;
    height: 700px;
}
.elements {
    display: flex;
    flex-direction: column;
}
.label {
    font-size: 15px;
    font-weight: light;
    justify-self: center;
}
.name {
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 8px;
    display: flex;
    justify-self: center;
    align-self: center;
}
.mid {
    margin-top: 20px;
    margin-bottom: 20px;
}
.subscribe {
    text-align: center;
}
.subscribe-button {
    padding: 10px 20px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.data-item {
    margin-bottom: 20px;
    white-space: nowrap;
    justify-self: center;
}
.divider {
    border-top: 1px solid #ccc;
    margin: 20px 0;
}
.data-item:last-child {
    margin-bottom: 50px;
}
.data-item:first-child {
    margin-top: 50px;
}
</style>
