<template>
  <nav v-if="!showMetricsconstant">
      <div class="intro-text">
        Ci siamo quasi. Siamo al quarto passo. Adesso ci concentriamo sulla community. Saper coinvolgere e interagire con attuali e potenziali consumatori è molto importante per il brand. 
        Come per lo Storytelling e per la Brand experience, il risultato è compreso tra 10 (hai costruito una gran bella community) a 0 (ci sono delle opportunità da cogliere). 
      </div>
        <!--  @CARLO! Aggiungere che bottone compare solo quando caricamento finito oppure senza bottone che appena caricamento finisce allora nuova pagina, che forse e anche meglio -->
          <ButtonTemplate v-if="sessionstore.communityLoaded" :button-text="'Continua'" @click="showMetrics" class="con-button"/>
  </nav>
  <nav v-if="showMetricsconstant">
    <CommunityResults :communityScore="sessionstore.communityScore" />
  </nav>
</template>

<script>
// import { ref } from "vue";
// import LoadingBar from '@/components/widgets/LoadingBar.vue';
import ButtonTemplate from '@/components/widgets/button.vue';
import CommunityResults from '@/components/screens/Posizionamento/CommunityMetrics.vue';
import  { useSessionStore } from '@/stores/sessionStore';

export default {
  setup () {
    const sessionstore = useSessionStore()

    return {
      sessionstore
    };
  },
name: "CommunityConfirm",
components: { 
  // LoadingBar,
  ButtonTemplate,
  CommunityResults
},
data() {
  return {
      showMetricsconstant: false,
  };
},
methods:{
  showMetrics() {
    this.showMetricsconstant = true;
    this.$emit('advance-step');
  },
},
}
</script>


<style lang="scss" scoped>

nav{
position: relative;
display: flex;
background: linear-gradient(to bottom, #2ecc71, #27ae60);
background-position: center;
flex-direction: column;
justify-content: center;
align-items: center; 
height: 100%; 
min-height: 100vh; 
}
.intro-text{
  position: flex;
  text-align: center;
  top: -50px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-left: 35px;
  margin-right: 35px;
}
.loading-bar{
  justify-content: center;
  display: flex;
  width: 50%;
}
.con-button{
  margin-bottom: 20px;
--button-border-color: #1f6402;
--button-text-color: #1f6402;
--button-background-color: #fff;
--button-background-hover-color: #1b560240;
&:hover{
  cursor: pointer;
}
}
</style>
