<template>
  <div class="check-metrics-page">
    <div class="metrics-box" v-if="!showMatrixconstant">
      <div class="title">
        <h2>Controlla i seguenti dati</h2>
      </div>
      <div class="metric-item" v-for="(flag, key) in sessionstoreFlags" :key="key">
        <span>{{ key }}:</span>
        <span class="yes-no">
          <input type="checkbox" v-model="sessionstore[flag]" :true-value="true" :false-value="false" />
        </span>
      </div>
    </div>
    <ButtonTemplate :button-text="'Conferma'" @click="() => { writeMetrics(); computeScores(); }"
      class="confirm-button" />
  </div>
</template>


<script>
import axios from 'axios';
import ButtonTemplate from '@/components/widgets/button.vue';
import { useSessionStore } from '@/stores/sessionStore';
import { supabase } from '@/lib/supabaseClient';

export default {
  setup() {
    const sessionstore = useSessionStore()

    return {
      sessionstore
    };
  },
  name: 'CheckMetrics',
  components: {
    ButtonTemplate,

  },
  data() {
    return {
      showMatrixconstant: false,
      storytellingScore: 0,
      experienceScore: 0,
      communityScore: 0,
      sessionstoreFlags: {
        'Sezione contatti nel sito': 'flag_contacts_section',
        'Pagina Instagram': 'flag_instagram_on_homepage',
        'Pagina Facebook': 'flag_facebook_on_homepage',
        'Newsletter': 'flag_newsletter',
        'Sito multilingue': 'flag_multilingual',
        'Negozio fisico': 'flag_physical_store',
        'Login sul sito': 'flag_login_option',
        'Negozio online': 'flag_online_shop'
      }
    };
  },

  methods: {
    showMatrix() {
      this.showMatrixconstant = true;
    },

    async writeMetrics() {
      // writes metrics to Supabase, using sessionstore data
      console.log('Writing metrics to Supabase...');
      const { error } = await supabase
        .from('sessions')
        .update(this.sessionstore.getMetrics)
        .eq('session_id', this.sessionstore.session_id);

      if (error) throw error;
    },
    // Method to compute the experience score
    async computeExperienceScore() {
      try {
        console.log('Computing experience score...');
        const pathExp = 'https://test-bbw-ozaoaorrha-ew.a.run.app/compute-experience';
        const requestData = this.sessionstore.getMetrics;

        const { data } = await axios.post(pathExp, requestData);

        this.experienceScore = data?.experience_score ?? 0; // Fallback to 0 if undefined
        this.sessionstore.setScore('experience', this.experienceScore);

        console.log('Experience score computed:', this.experienceScore);
      } catch (error) {
        console.error('Error computing experience score:', error);
        this.experienceScore = 0;
        this.sessionstore.setScore('experience', this.experienceScore);
      }
    },

    // Method to compute the storytelling score
    async computeStorytellingScore() {
      try {
        console.log('Computing storytelling score...');
        const pathStory = 'https://test-bbw-ozaoaorrha-ew.a.run.app/compute-storytelling';

        const { data } = await axios.post(pathStory, {
          instagram: this.sessionstore.instagram,
        });

        this.storytellingScore = data?.storytelling_score ?? 0; // Fallback to 0 if undefined
        this.sessionstore.setScore('storytelling', this.storytellingScore);

        console.log('Storytelling score computed:', this.storytellingScore);
      } catch (error) {
        console.error('Error computing storytelling score:', error);
        this.storytellingScore = 0;
        this.sessionstore.setScore('storytelling', this.storytellingScore);
      }
    },

    // Method to compute the community score
    async computeCommunityScore() {
      try {
        console.log('Computing community score...');
        const pathComm = 'https://test-bbw-ozaoaorrha-ew.a.run.app/compute-community';

        const { data } = await axios.post(pathComm, {
          flag_newsletter: this.sessionstore.flag_newsletter,
          instagram: this.sessionstore.instagram,
        });

        this.communityScore = data?.community_score ?? 2; // Fallback to 0 if undefined
        this.sessionstore.setScore('community', this.communityScore);

        this.storytellingScore = data?.storytelling_score ?? 0; // Fallback to 0 if undefined
        this.sessionstore.setScore('storytelling', this.storytellingScore);

        console.log('Community score computed:', this.communityScore);
      } catch (error) {
        console.error('Error computing community score:', error);
        this.communityScore = 2;
        this.sessionstore.setScore('community', this.communityScore);
        this.storytellingScore = 0;
        this.sessionstore.setScore('storytelling', this.storytellingScore);
      }
    },

    // Main method to compute all scores and update them in the database
    async computeScores() {
      this.$emit('click-metrics-confirm-button');

      // Compute each score individually
      await this.computeExperienceScore();
     /*  await this.computeStorytellingScore(); TODO: CARLO*/
      await this.computeCommunityScore();

      // After all computations, update the scores in the Supabase session table
      try {
        const { error } = await supabase
          .from('sessions')
          .update({
            status: 'completed',
            storytelling_score: this.storytellingScore,
            community_score: this.communityScore,
            experience_score: this.experienceScore,
          })
          .eq('session_id', this.sessionstore.session_id);

        if (error) {
          console.error('Error updating scores in Supabase:', error);
        } else {
          console.log('Scores updated successfully in Supabase');
        }
      } catch (error) {
        console.error('Error during Supabase update:', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.check-metrics-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  padding-top: 50px;
  /* Added padding to create space at the top */
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.metrics-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 500px;
  height: auto;
}

.metric-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.yes-no {
  cursor: pointer;

  &:hover {
    color: #1eb8b3;
  }
}

.confirm-button {
  margin-top: 10px;
}
</style>
