<template>
    <div class="try-button">
        <ButtonTemplate :button-text="'Inizia la demo gratuita'" @click="showPopup"/>
    </div>


</template>

<script>
import ButtonTemplate from './button.vue';


export default {
    name: 'TryButton',
    components: {
        ButtonTemplate,

    },

  }
</script>

<style lang="scss" scoped>
.try-button{
    --button-border-color: #007bff;
    --button-text-color: #007bff;
    --button-background-color: #fff;
    --button-background-hover-color: #00bfff8d;
  }
</style>
