<template>
    <div v-if="!ShowCommunityPage && !ShowStorytellingPage" class="search-results">
      <div class="title-section">
        <h2>Experience Metrics</h2>
      </div>
      <div class="score-section">
        <div class="score-info">
          <p>Abbiamo calcolato il tuo score</p>
          <div class="score-bar">
            <div class="score-fill" :style="{ width: sessionstore.experienceScore * 10 + '%' }"></div>
          </div>
          <div class="score-value">{{ sessionstore.experienceScore }}</div>
        </div>
      </div>
      <div class="score-feedback">
        <div v-if="sessionstore.experienceScore >= 0 && sessionstore.experienceScore <= 5" class="feedback-text">{{ TextPlaceholders[0] }}</div>
        <div v-else-if="sessionstore.experienceScore >= 6 && sessionstore.experienceScore <= 8" class="feedback-text">{{ TextPlaceholders[1] }}</div>
        <div v-else class="feedback-text">{{ TextPlaceholders[2] }}</div>
      </div>
    </div>
  </template>

  <script>  
  import { useSessionStore } from '@/stores/sessionStore';

  export default {
  setup() {
    const sessionstore = useSessionStore()

    return {
      sessionstore
    };
  },
    name: "ExperienceMetrics",
    components: { 
  },

    data() {
    return {
      ShowCommunityPage: false,
      ShowStorytellingPage: false,
      TextPlaceholders: [
        "L'azienda sembra non prestare molta attenzione all'esperienza del cliente oltre al prodotto stesso. Il servizio clienti è carente, la vendita online non è ottimizzata e manca la possibilità di personalizzazione. Questi elementi fondamentali contribuiscono a una brand experience negativa, che può danneggiare la percezione complessiva dell'azienda da parte dei clienti e ridurre la fedeltà del cliente",
        "L'azienda sta lavorando per migliorare l'esperienza del cliente, ma ci sono ancora aree in cui è necessario fare progressi. Il servizio clienti può essere migliorato, la vendita online è funzionale ma non ottimizzata, e la personalizzazione potrebbe essere più marcata. Anche se ci sono miglioramenti rispetto al voto precedente, l'azienda deve concentrarsi su un'esperienza più completa e soddisfacente per i clienti",
        "L'azienda offre un'esperienza del cliente eccezionale in tutti gli aspetti. Il servizio clienti è rapido ed efficiente, la vendita online è intuitiva e piacevole, e c'è un alto livello di personalizzazione disponibile per i clienti. Questi elementi contribuiscono a una brand experience straordinaria, che non solo soddisfa i clienti ma li fidelizza e promuove una reputazione positiva dell'azienda. Congratulazioni all'azienda per il suo impegno nell'offrire un'esperienza del cliente di alta qualità."
      ]
    };
  },
    methods: {
    showPage() {
      this.ShowCommunityPage = true;
    },
    showPreviousPage() {
      this.ShowCommunityPage = false;
      this.ShowStorytellingPage = true;
    },
  }
  };
  </script>
  
  <style scoped>
  .search-results {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    min-height: 100vh;
    top: 50px;
    margin-top: 50px;
  }
  
  .title-section {
    position: relative;
    left: 0px;
    margin-top: 20px;
    font-size: large;
  }
  
  .score-section {
    text-align: center;
    margin-top: 50px;
    top: 50px;
  }
  
  .score-info {
    display: flex;
    flex-direction: column;
  }
  
  .score-bar {
    width: 500px;
    height: 25px;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin: 10px auto;
    overflow: hidden;
  }
  
  .score-fill {
    height: 100%;
    background-color: #29d;
  }
  
  .score-value {
    font-size: 50px;
    font-weight: bold;
  }
  
  .metrics-section {
    margin-top: 20px;
  }
  
  .grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    margin-top: 30px;
  }
  
  .result {
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .title {
    padding: 10px;
    font-size: 13px;
    font-weight: lighter;
    text-align: left;
  }
  
  .content {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .metric {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .additional-info {
    margin-top: 20px;
  }
  
  .score-feedback {
    max-width: 70%;
    padding: 10px;
    border-radius: 15px;
    background-color: #29e176;
    text-align: center;
    margin: 0 auto; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .feedback-text {
    word-wrap: break-word;
  }
  </style>
  