import { createApp,h } from 'vue'
import { createPinia } from 'pinia';
import { getAuth } from 'firebase/auth';  // #TO-DO: Create separate file for all firebase stuff so not to have a huge main file

import App from './App.vue';
import router from './router'; 

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC2zEo8MGBgKY0WIrNxJIGC2AkmpNgDY7Y",
  authDomain: "bbw-webapp.firebaseapp.com",
  projectId: "bbw-webapp",
  storageBucket: "bbw-webapp.appspot.com",
  messagingSenderId: "417352247318",
  appId: "1:417352247318:web:e9a218df68490008852d37",
  measurementId: "G-GQ6KV3D7JL"
};

// Initialize Firebase
const firebase_app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

import VueProgressBar from "@aacassandra/vue3-progressbar";

const options = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "left",
  inverse: false,
};


const app  = createApp({
    render: ()=>h(App)
});

const pinia = createPinia();

app.use(router); 
app.use(pinia);
app.use(VueProgressBar, options);

app.mount('#app');

const auth = getAuth(firebase_app);

export { auth };

// Initialize stores
import { useAuthStore } from './stores/authStore';
const authStore = useAuthStore();

authStore.init();

