<template>
  <div v-if="loading" class="loading-screen">Attendi, stiamo caricando i tuoi dati.</div>
  <div v-else>
    <table>
      <thead>
        <tr>
          <th>Nome Azienda</th>
          <th>Numero Sessioni</th>
          <th>Score Trends</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(sessionGroup, companyName) in groupedSessions" :key="companyName">
          <td>{{ companyName }}</td>
          <td>{{ sessionGroup.length }}</td>
          <td>
            <div :id="'chart-' + companyName" style="width: 1041px; height: 300px;"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue';
import * as echarts from 'echarts';
import { supabase } from '@/lib/supabaseClient';
import { getAuth } from 'firebase/auth';

export default {
  setup() {
    const sessions = ref([]);
    const groupedSessions = ref({});
    const loading = ref(true);

    const fetchSessions = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;

        let { data: sessionsData, error } = await supabase
          .from('sessions')
          .select('session_id, date, company_id, status, experience_score, storytelling_score, community_score')
          .eq('user_id', userId)
          .order('date', { ascending: true });

        if (error) {
          console.error('Error fetching sessions:', error.message);
          return;
        }

        // Fetch company names and enrich session data
        for (let session of sessionsData) {
          const { data: company, error: companyError } = await supabase
            .from('companies')
            .select('company_name')
            .eq('company_id', session.company_id)
            .single();

          if (companyError) {
            console.error('Error fetching company data:', companyError.message);
            return;
          }

          session.company_name = company.company_name;
        }

        loading.value = false;

        // Group sessions by company name
        const grouped = sessionsData.reduce((acc, session) => {
          if (!acc[session.company_name]) {
            acc[session.company_name] = [];
          }
          acc[session.company_name].push(session);
          return acc;
        }, {});

        groupedSessions.value = grouped;
        sessions.value = sessionsData;

        // Wait for DOM updates to complete
        await nextTick();

        // Render charts
        renderCharts(grouped);
      }
    };

    const renderCharts = (groupedData) => {
      for (const [companyName, sessionGroup] of Object.entries(groupedData)) {
        const dates = sessionGroup.map(session => new Date(session.date).toLocaleDateString());
        const experienceScores = sessionGroup.map(session => session.experience_score);
        const storytellingScores = sessionGroup.map(session => session.storytelling_score);
        const communityScores = sessionGroup.map(session => session.community_score);

        const chartDiv = document.getElementById(`chart-${companyName}`);
        if (chartDiv) {
          const chart = echarts.init(chartDiv);
          const option = {
            tooltip: {
              trigger: 'axis',
            },
            legend: {
              data: ['Experience Score', 'Storytelling Score', 'Community Score'],
            },
            xAxis: {
              type: 'category',
              data: dates,
            },
            yAxis: {
              type: 'value',
            },
            series: [
              {
                name: 'Experience Score',
                type: 'line',
                data: experienceScores,
                color: 'red',
              },
              {
                name: 'Storytelling Score',
                type: 'line',
                data: storytellingScores,
                color: 'blue',
              },
              {
                name: 'Community Score',
                type: 'line',
                data: communityScores,
                color: 'green',
              },
            ],
          };
          chart.setOption(option);
        } else {
          console.error(`Chart div with id chart-${companyName} not found`);
        }
      }
    };

    onMounted(fetchSessions);

    return {
      groupedSessions,
      loading
    };
  },
};
</script>

  
  <style scoped>
  .loading-screen{
    margin-top: 100px;
    display: flex;
    align-self: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 20px; /* Adds margin on the right and left */
    margin-top: 100px;
  }
  th, td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  canvas {
    width: 100%;
    height: 80px;

  }
  </style>
  
  