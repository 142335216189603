<template>
  <div class="pricing-page">
    <!-- Background Image and Centered Text -->
    <div class="background-overlay">
      <h1 class="centered-text">
        Scegli un piano per monitorare la tua azienda <br />
        e migliorare il tuo lato marketing
      </h1>
    </div>
    <!-- Pricing Content -->
    <div class="content-wrapper">
      <div class="user-container">
        <div class="basic">
          <BasicUser userPlanName="Utente Basic" nameColor="rgb(80, 80, 80)" />
        </div>
        <div class="premium">
          <BasicUser userPlanName="Utente Premium" nameColor="rgb(109, 199, 248)" price=11 />
        </div>
        <div class="elite">
          <BasicUser userPlanName="Utente Elite" nameColor="rgb(38, 166, 91)" price=15 />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicUser from '../screens/BasicUser.vue';

export default {
  name: 'PricingPage',
  components: {
    BasicUser,
  },
};
</script>

<style scoped>
.pricing-page {
  position: relative;
  min-height: 100vh;
  background: url('@/assets/Images/Sfondo3.png') no-repeat center center;
  background-size: 100% auto;
  background-position: top;
}

.background-overlay {
  text-align: center;
  padding: 100px 20px 0px; 
  color: white;
}

.centered-text {
  font-size: 36px;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  margin: 0;
}

.content-wrapper {
  position: relative;
  z-index: 1;
  padding-top: 20px; /* Adjust padding to create space between the text and content */
}

.user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 40px;
}

.basic {
  flex: 1;
  margin-left: 40px;
}

.premium,
.elite {
  flex: 1;
}
</style>
