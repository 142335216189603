<template>
  <div class="step-indicator">
    <div class="backward">
        <ArrowRight @click="PreviousStep"> </ArrowRight>
    </div>
    <div
      v-for="(step, index) in steps"
      :key="index"
      :class="{ 'step': true, 'active': index === currentStep }"
    >
      {{ step }}
    </div>
    <div class="forward">
        <ArrowRight @click="NextStep"> </ArrowRight>
    </div>
    <div v-if="showTooltip" class="tooltip" :style="tooltipStyle">
      <div class="arrow"> 
          <ArrowTopLeft/>
      </div>
      <span class="text-pos">Sei attualmente a questo step</span>
    </div>
  </div>
</template>

<script>
import ArrowTopLeft from "@/assets/Icons/ArrowTopLeft.vue"
import ArrowRight from "@/assets/Icons/ArrowRight.vue";
export default {
  name: "StepIndicator",
  components:{
  ArrowTopLeft,
  ArrowRight
},
  props: {
    currentStep: {
      type: Number,
      required: true,
      validator: value => value >= 0 && value <= 3
    }
  },
  data() {
    return {
      steps: ["Onboarding", "Storytelling", "Experience", "Community"],
      showTooltip: false,
      tooltipIndex: 0
    };
  },
  computed: {
    tooltipStyle() {
      // Calculate position of tooltip based on the currentStep
      const left = (this.currentStep * 107) - 100 + 'px'; // Assuming each step is 70px wide
      return { left };
    }
  },
  mounted() {
    this.showTooltip = true; // Show tooltip when component is mounted
  },
  methods: {
    NextStep() {
      this.$emit('forward-event');
    },
    PreviousStep() {
      this.$emit('back-event');
    },
  }
};
</script>

<style lang='scss' scoped>
  @import url("https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap");

.step-indicator {
  display: flex;
  position: relative;
}

.step {
  width: 100px;
  height: 50px;
  margin: 7px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  background-color: #29d;
  color: #fff;
}

.tooltip {
  font-size: 20px;
  position: absolute;
  bottom: -50px;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-family: 'Comic Neue'!important;
}

.arrow {
  position: absolute;
  bottom: -10px;
  left: calc(50% - 5px);
  width: 45px;
  height: 45px;
}
.text-pos{
  position: relative;
  bottom: -50px;
  overflow: hidden;
  white-space: nowrap;
}

.backward{
  transform: rotate(180deg);
  position: relative;
  top: 18px; // Find a way to not make this manual to align 
  margin-right: 5px;
}
.forward{
  position: relative;
  top:-20px;  // Find a way to not make this manual to align
  margin-left: 5px;
}
</style>