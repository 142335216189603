<template>
  <div class="matrix-page">
    <div class="canvas-container" ref="container"></div>
  </div>
</template>
  
  <script>
  import * as THREE from 'three';
  import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
  import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

  import { useSessionStore } from '@/stores/sessionStore';
  
  export default {
    setup() {
      const sessionstore = useSessionStore();

      const ratings = [[sessionstore.storytellingScore, sessionstore.experienceScore, sessionstore.communityScore]];
      console.log(ratings)

      return {
        sessionstore,
        ratings,
      };
    },
    name: 'MatrixPage',
    props: {
      names: {
        type: Array,
        required: true
      }
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {

        // Calculate aspect ratio
        const aspectRatio = window.innerWidth / window.innerHeight;

        // Create camera
        const camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000);

        // Adjust camera position to center the matrix and make it bigger
        console.log(this.ratings)
        const maxData = this.ratings.length > 1 ? Math.max(...this.ratings.flat()) : Math.max(...this.ratings[0]);
        const distanceToCenter = maxData * 2;
        camera.position.set(distanceToCenter, distanceToCenter, distanceToCenter);
        camera.lookAt(0, 0, 0); // Look at the center of the scene

        // Create renderer
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0xd0ffd0, 0); // Set light green background color (trasparente, cambiare lo 0)
        renderer.setPixelRatio(window.devicePixelRatio)    // Per migliorare Resolution, non ho idea di come impatti performance
        this.$refs.container.appendChild(renderer.domElement);

        // Create scene
        const scene = new THREE.Scene();

        // Create controls
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableRotate = true; // Enable rotation
        controls.enableZoom = true; // Enable zooming

        // Create axes
        const axesHelper = new THREE.AxesHelper(distanceToCenter); // Length of the axes adjusted
        scene.add(axesHelper);
        
  
        // Create text geometry for "Storytelling"
        const fontLoader = new FontLoader();
        fontLoader.load('https://raw.githubusercontent.com/mrdoob/three.js/master/examples/fonts/helvetiker_bold.typeface.json', font => {
            // Create text geometry for "Storytelling"
            const storytellingTextGeometry = new TextGeometry("Storytelling", {
            font: font,
            size: 0.2,
            height:0,
            depth: 0,
            curveSegments: 50,
            bevelEnabled: true,
            bevelThickness: 0,
            bevelSize: 0,
            bevelOffset: 0,
            bevelSegments: 5
            });
            storytellingTextGeometry.computeBoundingBox();
            const storytellingBoundingBox = storytellingTextGeometry.boundingBox;
            const storytellingTextWidth = storytellingBoundingBox.max.x - storytellingBoundingBox.min.x;

            const storytellingTextMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000  });
            const storytellingText = new THREE.Mesh(storytellingTextGeometry, storytellingTextMaterial);
            storytellingText.position.set(-storytellingTextWidth / 2, 3, 0); // Adjusted position based on bounding box
            storytellingText.rotation.set(Math.PI / 2, Math.PI / 2, 0);
            scene.add(storytellingText);

            const experienceTextGeometry = new TextGeometry("Experience", {
            font: font,
            size: 0.2,
            height:0,
            depth: 0,
            curveSegments: 50,
            bevelEnabled: true,
            bevelThickness: 0,
            bevelSize: 0,
            bevelOffset: 0,
            bevelSegments: 5
            });
            experienceTextGeometry.computeBoundingBox();
            const experienceBoundingBox = experienceTextGeometry.boundingBox;
            const experienceTextWidth = experienceBoundingBox.max.x - experienceBoundingBox.min.x;

            const experienceTextMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000  });
            const experienceText = new THREE.Mesh(experienceTextGeometry, experienceTextMaterial);
            experienceText.position.set(-experienceTextWidth / 2, 0, 5); // Adjusted position based on bounding box
            experienceText.rotation.set(Math.PI / 0.66, Math.PI / 2, Math.PI / 2);
            scene.add(experienceText);

            const communityTextGeometry = new TextGeometry("Community", {
            font: font,
            size: 0.2,
            height:0,
            depth: 0,
            curveSegments: 50,
            bevelEnabled: true,
            bevelThickness: 0,
            bevelSize: 0,
            bevelOffset: 0,
            bevelSegments: 5
            });

            communityTextGeometry.computeBoundingBox();
            const communityBoundingBox = communityTextGeometry.boundingBox;
            const communityTextWidth = communityBoundingBox.max.x - communityBoundingBox.min.x;

            const communityTextMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000  });
            const communityText = new THREE.Mesh(communityTextGeometry, communityTextMaterial);
            communityText.position.set(3, 0, -communityTextWidth / 2); // Adjusted position based on bounding box
            communityText.rotation.set(Math.PI , Math.PI, Math.PI );
            scene.add(communityText);

        });
        this.ratings.forEach((point, index) => {
            const x = point[1];
            const y = point[2];
            const z = point[0];
            const name = this.names[index];
            
            //console.log(`Position for point ${index}: x=${x}, y=${y}, z=${z}, name=${name}`);
            
            // 2D Text
            fontLoader.load('https://raw.githubusercontent.com/mrdoob/three.js/master/examples/fonts/helvetiker_bold.typeface.json', font => {
            const generateTextTexture = (text) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const width = 256; 
            const height = 64; 
            canvas.width = width;
            canvas.height = height;

            context.font = font; 
            context.fillStyle = '#0000ff'; 
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillText(text, width / 2, height / 2);

            const texture = new THREE.Texture(canvas);
            texture.needsUpdate = true;

            return texture;
          };
          
          const nomeaziendaTextMaterial = new THREE.SpriteMaterial({ map: generateTextTexture(name) });
          const nomeazienda = new THREE.Sprite(nomeaziendaTextMaterial);
          nomeazienda.scale.set(3, 1, 1); 
          nomeazienda.position.set(x,y- 1.0, z);
          scene.add(nomeazienda);
        });
        // Check if this.data has at least one element before accessing its properties
        if (this.ratings.length > 0) {
          // Create line geometry
          const lineGeometry = new THREE.BufferGeometry().setFromPoints([
            new THREE.Vector3(0, 0, 0),  // Origin
            new THREE.Vector3(x, y, z)   // Point from data
          ]);
  
          // Create line material
          const lineMaterial = new THREE.LineBasicMaterial({ 
            color: 0xff0000, 
            transparent: true, 
            opacity: 0.1 
          });
  
          // Create line
          const line = new THREE.Line(lineGeometry, lineMaterial);
  
          // Add line to the scene
          scene.add(line);

          // Create a sphere geometry to represent company
          const spheregeometry = new THREE.SphereGeometry(0.5, 64, 64);
          const spherematerial = new THREE.MeshBasicMaterial({ color: '#0000ff' });
          const azienda = new THREE.Mesh(spheregeometry, spherematerial);

          azienda.position.set(x, y, z);

          // Add the sphere to the scene
          scene.add(azienda);

        }
      });
        // Render loop
        const animate = () => {
          requestAnimationFrame(animate);
          renderer.render(scene, camera);
        };
  
        animate();
      },
      loadFont() {
        const fontLoader = new FontLoader();
        return fontLoader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json');
      }
    }
  };
  </script>
  
  <style scoped>
.matrix-page {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}


.canvas-container {
  flex: 2;
  position: relative;
}
</style>

  