<template>
    <button :class="buttonClass">{{ buttonText }}</button>
  </template>
  
  <script>
  export default {
    name: 'ButtonTemplate',
    props: {
      buttonText: {
        type: String,
        default: '' // Default text if not provided
      },
      buttonTextColor: {
        type: String,
        default: '#fff' 
      },
      buttonBackgroundColor: {
        type: String,
        default: '#007bff' 
      },
      buttonBackgroundHoverColor: {
        type: String,
        default: '#007bff' 
      },
      buttonBorderColor: {
        type: String,
        default: 'transparent' 
      },
      buttonShape: {
        type: String,
        default: 'rounded' 
      }
    },
    computed: {
      buttonClass() {
        return `custom-button ${this.buttonShape}`;
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .custom-button {
    padding: 10px 20px;
    color: var(--button-text-color, #fff); 
    background-color: var(--button-background-color, #007bff); 
    border: 1px solid var(--button-border-color, transparent); 
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .rounded {
    border-radius: 5px;
  }
  
  .square {
    border-radius: 0;
  }
  
  .custom-button:hover {
  background-color: var(--button-background-hover-color, #0056b3);
}
  </style>
  

