<template>
    <div class="top">
      <!-- Inserire pagina Casi d'uso -->
      <div class="title">
        <h2>La pagina Casi d'Uso verrà presto sviluppata</h2>
       </div>
    </div>
</template>

<script>
export default {
  name: 'CasiUso',
  components: {
  }
};
</script>

<style lang="scss" scoped>
.title{
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;
  color: #b9471a;
  background-color: #7ebbaf74;
  color-interpolation-filters: linearRGB;
}
</style>