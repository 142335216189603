import { defineStore } from 'pinia';

export const useSessionStore = defineStore('session', {
    state: () => ({
        timestamp: 0,
        session_id: '',
        experienceScore: 0,
        experienceLoaded: false,
        storytellingScore: 0,
        storytellingLoaded: false,
        communityScore: 0,
        communityLoaded: false,
        company_name: '',
        company_id: '',
        website: '',
        flag_contacts_section: false,
        flag_instagram_on_homepage: false,
        flag_facebook_on_homepage: false,
        flag_newsletter: false,
        flag_multilingual: false,
        flag_physical_store: false,
        flag_login_option: false,
        flag_online_shop: false,
        instagram: '',
        facebook: '',
        linkedin: ''
    }),

    actions: {
        init(session_id, timestamp, company_name, company_id, website) {
            this.session_id = session_id;
            this.timestamp = timestamp;
            this.company_name = company_name;
            this.company_id = company_id;
            this.website = website;
        },

        setMetrics(metrics) {
            this.flag_contacts_section = metrics.flag_contacts_section;
            this.flag_instagram_on_homepage = metrics.flag_instagram_on_homepage;
            this.flag_facebook_on_homepage = metrics.flag_facebook_on_homepage;
            this.flag_newsletter = metrics.flag_newsletter;
            this.flag_multilingual = metrics.flag_multilingual;
            this.flag_physical_store = metrics.flag_physical_store;
            this.flag_login_option = metrics.flag_login_option;
            this.flag_online_shop = metrics.flag_online_shop;
        },

        setSocial(social) {
            this.instagram = social.instagram;
            this.facebook = social.facebook;
            this.linkedin = social.linkedin;
        },

        setScore(scoreType, score) {
            if (scoreType === 'storytelling') {
                this.storytellingScore = score;
                this.storytellingLoaded = true;
            } else if (scoreType === 'community') {
                this.communityScore = score;
                this.communityLoaded = true;
            } else if (scoreType === 'experience') {
                this.experienceScore = score;
                this.experienceLoaded = true;
            } else {
                console.error('Invalid scoreType');
            }
        },
        reset() {
            this.communityScore = 0;
            this.storytellingLoaded = false;
            this.experienceScore = 0;
            this.communityLoaded = false;
            this.storytellingScore = 0;
            this.experienceLoaded = false;
          }
    },

    getters: {
        getMetrics() {
            return {
                flag_contacts_section: this.flag_contacts_section,
                flag_instagram_on_homepage: this.flag_instagram_on_homepage,
                flag_facebook_on_homepage: this.flag_facebook_on_homepage,
                flag_newsletter: this.flag_newsletter,
                flag_multilingual: this.flag_multilingual,
                flag_physical_store: this.flag_physical_store,
                flag_login_option: this.flag_login_option,
                flag_online_shop: this.flag_online_shop,
            };
        },
        getSocial() {
            return {
                instagram: this.instagram,
                facebook: this.facebook,
                linkedin: this.linkedin
            };
        },
    }
});