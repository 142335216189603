<template>
  <div class="profile-view">
    <div class="background-user">
      <div class="green-box top-right"></div>
      <div class="green-box bottom-left"></div>
      <div class="profile-content">
        <div class="left-column">
          <div class="user-info">
            <div class="profile-picture-container">
              <img v-if="userPicture" :src="userPicture" alt="User Picture" class="profile-picture">
              <div v-else class="profile-picture-placeholder">
                <span class="initials">
                  {{ getInitials(username) }}
                </span>
              </div>
            </div>
            <div class="user-details">
              <h2>{{ username }}</h2>
              <p>{{ company_name }}</p>
            </div>
          </div>
          <div class="social-links">
            <div class="social-link" v-for="(url, name) in companySocialLinks" :key="name">
              <span class="icon">{{ name }}</span>
              <a :href="url" target="_blank">{{ url }}</a>
            </div>
          </div>
        </div>
        <div class="right-column">
          <div class="account-info">
            <div class="info-row">
              <span class="info-label">Name:</span>
              <span class="info-value">{{ accountName }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Email:</span>
              <span class="info-value">{{ email }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Plan:</span>
              <span class="info-value">{{ planType }}</span>
            </div>
          </div>
          <div class="past-analyses">
            <div class="scrollable-table-container" v-if="showAllSessions">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Company</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="session in sessions" :key="session.session_id">
                    <td>{{ session.date }}</td>
                    <td>{{ session.company_name }}</td>
                    <td>{{ session.status }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Company</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="session in sessions.slice(0, 5)" :key="session.session_id">
                    <td>{{ session.date }}</td>
                    <td>{{ session.company_name }}</td>
                    <td>{{ session.status }}</td>
                  </tr>
                </tbody>
              </table>
              <button @click="loadMoreSessions">Show More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from '@/lib/supabaseClient';
import { getAuth } from 'firebase/auth';

export default {
  name: 'ProfileView',
  data() {
    return {
      username: '',
      company_name: '',
      userPicture: '', 
      companySocialLinks: {
        Facebook: '',
        Instagram: '',
        Twitter: '',
        Tiktok: '',
        Youtube: '',
      },
      accountName: '',
      email: '',
      planType: '',
      sessions: [],
      showAllSessions: false,
    };
  },
  methods: {
    getInitials(fullName) {
      const names = fullName.split(' ');
      const initials = names.map(name => name.charAt(0).toUpperCase()).join('');
      return initials;
    },
    async loadSessions() {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userId = user.uid;

        // Fetch the last 5 sessions
        let { data: sessions, error } = await supabase
          .from('sessions')
          .select('session_id, date, company_id, status')
          .eq('user_id', userId)
          .order('date', { ascending: false })
          .limit(5);

        if (error) {
          console.error('Error fetching sessions:', error.message);
          return;
        }

        // Fetch company names
        for (let session of sessions) {
          const { data: company, error: companyError } = await supabase
            .from('companies')
            .select('company_name')
            .eq('company_id', session.company_id)
            .single();

          if (companyError) {
            console.error('Error fetching company data:', companyError.message);
            return;
          }

          session.company_name = company.company_name;
        }

        this.sessions = sessions;
      }
    },
    async loadMoreSessions() {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userId = user.uid;

        // Fetch all sessions
        let { data: allSessions, error } = await supabase
          .from('sessions')
          .select('session_id, date, company_id, status')
          .eq('user_id', userId)
          .order('date', { ascending: false });

        if (error) {
          console.error('Error fetching sessions:', error.message);
          return;
        }

        // Fetch company names
        for (let session of allSessions) {
          const { data: company, error: companyError } = await supabase
            .from('companies')
            .select('company_name')
            .eq('company_id', session.company_id)
            .single();

          if (companyError) {
            console.error('Error fetching company data:', companyError.message);
            return;
          }

          session.company_name = company.company_name;
        }

        this.sessions = allSessions;
        this.showAllSessions = true;
      }
    }
  },
  async created() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const userId = user.uid;

      // Fetch user data
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', userId)
        .single();

      if (userError) {
        console.error('Error fetching user data:', userError.message);
        return;
      }

      if (userData) {
        this.username = `${userData.first_name} ${userData.last_name}`;
        this.accountName = `${userData.first_name} ${userData.last_name}`;
        this.email = userData.email;

        // Fetch user account details
        const { data: userAccountData, error: userAccountError } = await supabase
          .from('user_account')
          .select('type_of_plan')
          .eq('user_id', userId)
          .single();

        if (userAccountError) {
          console.error('Error fetching user account data:', userAccountError.message);
          return;
        }

        if (userAccountData) {
          // Fetch plan type description
          const { data: planData, error: planError } = await supabase
            .from('plan_types')
            .select('description')
            .eq('plan_id', userAccountData.type_of_plan)
            .single();

          if (planError) {
            console.error('Error fetching plan type data:', planError.message);
            return;
          }

          this.planType = planData ? planData.description : '';

          // Fetch company details if company_id exists
          const { data: userCompanyData, error: userCompanyError } = await supabase
            .from('user_company')
            .select('company_id')
            .eq('user_id', userId) 
            // .eq('is_workplace', true) TODO: Uncomment this line if you have a workplace flag
            .single();

          if (userCompanyError) {
            console.error('Error fetching user company data:', userCompanyError.message);
            return;
          }

          if (userCompanyData && userCompanyData.company_id) {
            const { data: companyData, error: companyError } = await supabase
              .from('companies')
              .select('*')
              .eq('company_id', userCompanyData.company_id)
              .single();

            if (companyError) {
              console.error('Error fetching company data:', companyError.message);
              return;
            }

            if (companyData) {
              this.company_name = companyData.company_name;
              this.companySocialLinks = {
                Facebook: companyData.facebook || '',
                Instagram: companyData.instagram || '',
                Twitter: companyData.twitter || '',
                Tiktok: companyData.tiktok || '',
                Youtube: companyData.youtube || '',
              };
            }
          }
        }
      }

      // Load sessions data
      this.loadSessions();
    } else {
      console.error('No user is currently signed in.');
    }
  },
};
</script>
  
<style scoped>
.profile-view {
  background-color: #f8f8f8;
  position: relative;
  margin-top: 80px;
}

.background-user {
  position: relative;
  width: 100%;
  min-height: max-content;
}

.green-box {
  position: absolute;
  background-color: green;
}

.top-right {
  width: 175px;
  height: 200px;
  top: 0;
  right: 0;
}

.bottom-left {
  width: 250px;
  height: 200px;
  bottom: 0;
  left: 0;
}

.profile-content {
  display: flex;
  justify-content: space-between;
  padding: 40px;
}

.left-column, .right-column {
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.left-column {
  width: 35%;
  margin-right: 20px; 
}

.right-column {
  width: 65%;
}

.user-info, .social-links, .account-info, .past-analyses {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.profile-picture-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.profile-picture-placeholder {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; 
  font-size: 4em; 
  font-weight: bold; 
}

.initials {
  text-align: center;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.info-label {
  font-weight: bold;
}

.info-value {
  flex-basis: 90%; /* Adjust this to control value width */
}

.social-link {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

.scrollable-table-container {
  max-height: 300px; /* Set the max height as per your design */
  overflow-y: auto;
}

</style>
