<template>
    <nav v-if="!showMetricsconstant">
        <div class="intro-text">
            Adesso che ti conosciamo meglio possiamo cominciare l’analisi del brand. Iniziamo con il secondo passo.
            Partiamo dallo storytelling studiando la tua comunicazione sui social media. In questa fase analizzeremo come e quanto la tua comunicazione è efficace. 
            Misureremo la tua capacità di costruire un discorso organizzato attorno al brand. 
            Il risultato va da un massimo di 10 (complimenti, la tua comunicazione è molto efficace!) al minimo di 0 (ci sono molti margini di miglioramento ;))
        </div>
            <ButtonTemplate v-if="sessionstore.storytellingLoaded" :button-text="'Continua'" @click="showMetrics" class="con-button"/>
    </nav>
    <nav v-if="showMetricsconstant">
      <StoryTellingResults/>
    </nav>
</template>

<script>
// import { ref } from "vue";
// import LoadingBar from '@/components/widgets/LoadingBar.vue';
import ButtonTemplate from '@/components/widgets/button.vue';
import StoryTellingResults from '@/components/screens/Posizionamento/StoryTellingMetrics.vue';
import  { useSessionStore } from '@/stores/sessionStore';

export default {
  setup () {
    const sessionstore = useSessionStore()
    return {
      sessionstore
    };
  },

  name: "StoryTellingConfirm",
  components: { 
    // LoadingBar,
    ButtonTemplate,
    StoryTellingResults
  },
  data() {
    return {
        showMetricsconstant: false,
      };
  },
  methods:{
    showMetrics() {
      this.showMetricsconstant = true;
      this.$emit('advance-step');
    },
  }
};

</script>


<style lang="scss" scoped>

nav{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  height: 100%; 
  min-height: 100vh; 
}
.intro-text{
    position: flex;
    text-align: center;
    top: -50px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-left: 35px;
    margin-right: 35px;
}
.loading-bar{
    justify-content: center;
    display: flex;
    width: 50%;
}
.con-button{
    margin-bottom: 20px;
  --button-border-color: #1f6402;
  --button-text-color: #1f6402;
  --button-background-color: #fff;
  --button-background-hover-color: #1b560240;
  &:hover{
    cursor: pointer;
  }
  }
</style>