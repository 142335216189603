<template>
  <div class="page">
    <div v-if="!showConfermaSocialMedia" class="input">
      <div class="title">
        <h3>
          Ciao! Benvenuto nel percorso di valutazione e posizionamento del tuo brand.
          Il percorso è composto da 4 passi. 
          Cominciamo con il primo passo che serve a conoscere la presenza online del tuo brand!
        </h3>   
      </div>
      <div class="form-container">
        <div class="form-group">
          <label for="company_name" class="input-text">Inserisci il nome della tua azienda:</label>
          <input
            type="text"
            id="company_name"
            v-model="company_name"
            @input="fetchCompanySuggestions"
            @focus="showSuggestions = true"
            @blur="hideSuggestions"
            class="bar"
          />
          <ul v-if="showSuggestions" class="suggestions-list">
            <li v-for="suggestion in suggestions" :key="suggestion.company_id" @click="selectSuggestion(suggestion)">
              {{ suggestion.company_name }}
            </li>
          </ul>
        </div>
        <div class="form-group">
          <label for="sector" class="input-text">Inserisci il settore della tua azienda:</label>
          <input type="text" id="sector" v-model="sector" class="bar"/>
        </div>
        <div class="form-group">
          <label for="website" class="input-text">Inserisci l'URL del tuo sito Web:</label>
          <input type="text" id="website" v-model="website" class="bar"/>
        </div>
        <div class="checkbox">
          <input type="checkbox" id="noWebsite" v-model="noWebsite" />
          <label for="noWebsite">Clicca qui se non hai un sito web</label>
        </div>
        <div class="invia-button">
          <ButtonTemplate :button-text="'Invia'" @click="submitForm"/>
        </div>
      </div>
    </div>

    <div v-if="showConfermaSocialMedia" class="social">
      <ConfermaSocialMedia/>
    </div>

    <!-- Custom Modal for confirming session continuation -->
    <div v-if="showPopup" class="custom-modal-overlay" @click="closeModal">
      <div class="custom-modal" @click.stop>
        <div class="custom-modal-header">
          <h3>Sessione Esistente</h3>
        </div>
        <div class="custom-modal-body">
          Abbiamo trovato un sessione già iniziata da te per la stessa azienda il {{ existingActiveSession.date }}. Vuoi continuare o iniziarne una nuova?
        </div>
        <div class="custom-modal-footer">
          <button @click="continueExistingActiveSession(existingActiveSession)" class="btn-success">Continua quella esistente</button>
          <button @click="cancelAndStartNewSession(existingActiveSession, this.userId)" class="btn-danger">Inizia una nuova</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonTemplate from '../../widgets/button.vue';
import ConfermaSocialMedia from './ConfermaSocialMedia.vue';
import { supabase } from '@/lib/supabaseClient';
import { auth } from '@/main.js'
import { useSessionStore } from '@/stores/sessionStore';

export default {
  setup() {
    const sessionstore = useSessionStore();
    return { sessionstore };
  },

  name: 'StartInputBar',
  components: {
    ButtonTemplate,
    ConfermaSocialMedia
  },
  data() {
    return {
      company_name: '',
      company_id: '',
      sector: '',
      website: '',
      noWebsite: false,
      showConfermaSocialMedia: false,
      showSuggestions: false,
      suggestions: [],
      existingActiveSession: { date: null }, 
      showPopup: false,
      userId: ''
    };
  },
  methods: {
    async fetchCompanySuggestions() {
      if (this.company_name.length > 1) {
        const { data, error } = await supabase
          .from('companies')
          .select('company_id, company_name, sector, website')
          .ilike('company_name', `%${this.company_name}%`);

        if (error) {
          console.error('Error fetching company suggestions:', error.message);
          return;
        }

        this.suggestions = data;
        this.showSuggestions = true;
      } else {
        this.suggestions = [];
      }
    },

    async selectSuggestion(suggestion) {
      this.company_name = suggestion.company_name;
      this.sector = suggestion.sector;
      this.website = suggestion.website;
      this.showSuggestions = false;
    },

    checkFieldsCompleteness() {
      if (!this.noWebsite && (!this.company_name || !this.website)) {
        alert('Compila entrambi i campi prima di procedere.');
        return false;
      } else if (this.noWebsite && !this.company_name) {
        alert('Compila il campo del nome dell\'azienda prima di procedere.');
        return false;
      }
    },

    checkAuth() {
      const user = auth.currentUser;
      this.userId = user ? user.uid : null;

      if (!this.userId) {
        console.error('User not authenticated');
        return false;
      }
    },

    async submitForm() {
      if (this.checkFieldsCompleteness() === false) {
        return;
      }
      if (this.checkAuth() === false) {
        return;
      }
      
      // check if company already exists in database
      const { data: existingCompany, error: fetchError } = await supabase
        .from('companies')
        .select('company_id')
        .eq('company_name', this.company_name)
        .limit(1)
        .maybeSingle();
      if (fetchError) {
        console.error('Error checking existing company:', fetchError.message);
        return;
      }

      // if company does not exist, insert it
      if (!existingCompany) {
        const { data: newCompany, error: insertError } = await supabase
          .from('companies')
          .insert([{ company_name: this.company_name, sector: this.sector, website: this.website }])
          .select('company_id')
          .single();
        if (insertError) {
          console.error('Error inserting new company:', insertError.message);
          return;
        }
        this.company_id = newCompany.company_id;
      } else {

        // if company already exists, use its id fetched from the database
        this.company_id = existingCompany.company_id;
      }

      const { data: existingActiveSession, error: sessionFetchError } = await supabase
        .from('sessions')
        .select('session_id, date, status')
        .eq('user_id', this.userId)
        .eq('company_id', this.company_id)
        .eq('status', 'active')
        .maybeSingle();

      if (sessionFetchError) {
        console.error('Error checking existing session:', sessionFetchError.message);
        return;
      }

      // if an active session already exists, show a popup to ask the user if they want to continue it
      if (existingActiveSession) {
        this.existingActiveSession = existingActiveSession;
        this.showPopup = true;
      } else {
        this.startNewSession(this.userId);
      }
    },

    async startNewSession(userId) {
      const session_id = Math.random().toString(36).substring(2, 27);
      const timestamp = new Date().toISOString();

      const { error: sessionError } = await supabase
        .from('sessions')
        .insert([{ 
          session_id: session_id, 
          date: timestamp, 
          company_id: this.company_id, 
          user_id: userId, 
          status: 'active' }]);

      if (sessionError) {
        console.error('Error inserting new session:', sessionError.message);
        return;
      }

      // save session attributes to pinia store
      this.sessionstore.init(
        session_id,
        timestamp,
        this.company_name,
        this.company_id,
        this.website
      );

      this.showConfermaSocialMedia = true;
    },
    async continueExistingActiveSession(existingActiveSession) {
      // simply save 
      this.sessionstore.init(
        existingActiveSession.session_id,
        existingActiveSession.date,
        this.company_name,
        this.company_id,
        this.website
        );
      this.showPopup = false;
      this.showConfermaSocialMedia = true;
    },

    async cancelAndStartNewSession(existingActiveSession, userId) {
      const { error: updateError } = await supabase
        .from('sessions')
        .update({ status: 'cancelled' })
        .eq('session_id', existingActiveSession.session_id);

      console.log("session id:", existingActiveSession)

      if (updateError) {
        console.error('Error updating session status:', updateError.message);
        return;
      }

      this.showPopup = false;
      this.startNewSession(userId);
    },
    closeModal() {
      this.showPopup = false;
    },
  }
};
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
}

.social {
  position: relative;
}

.page {
  position: relative;
  background: linear-gradient(to bottom, #2ecc71, #27ae60);
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
}

.title {
  margin-bottom: 20px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 30px;
  color: white;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items:   center;
  margin-bottom: 100px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.checkbox {
  margin-bottom: 15px;
  color: #c9c9c9;
  font-style: italic;
}

.input-text {
  margin-bottom: 10px;
  font-size: 20px;
}

.bar {
  width: 700px;
  height: 50px;
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  position: absolute;
  width: 700px;
  z-index: 1000;
  max-height: 200px;
  top: 38%;
  overflow-y: auto;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

label {
  margin-left: 5px;
}

.invia-button {
  --button-border-color: #007bff;
  --button-text-color: #007bff;
  --button-background-color: #fff;
  --button-background-hover-color: #00bfff8d;
}

/* Custom Modal Styles */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-modal-header {
  margin-bottom: 20px;
}

.custom-modal-body {
  margin-bottom: 20px;
}

.custom-modal-footer {
  display: flex;
  justify-content: space-between;
}

.btn-success {
  background-color: #00c853;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-danger {
  background-color: #d32
}

</style>
