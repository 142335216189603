<!-- Preso e cambiato da https://github.com/bittokazi/vue-spider-graph -->
<template>
  <div id="questionsPage">
    <canvas ref="graph"></canvas>
  </div>
</template>

<script>
import { useSessionStore } from '@/stores/sessionStore';

export default {
  setup() {
    const sessionstore = useSessionStore()

    return {
      sessionstore
    };
  },
  name: "SpiderGraph",
  props: {
    qid: String,
    predefinedRatings: Array,
    dataRatings: Array,
    width: Number,
    height: Number,
    legend: Array,
    numberOfDataPoints: {
      type: Number,
      default: 1
    },
    dataRatingsColors: Array, 
    dataRatingsGridColors: Array 
  },
  mounted() {
    this.$refs["graph"].height = this.height || 500;
    this.$refs["graph"].width = this.width || 500;
    this.context = this.$refs["graph"].getContext("2d");
    this.radarData = [[this.sessionstore.storytellingScore, this.sessionstore.experienceScore, this.sessionstore.communityScore]];
    this.draw();
  },
  methods: {
    draw() {
      if (!this.context) return;
      const ctx = this.context;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      for (let i = 0; i < this.radarData.length; i++) {
        this.drawRadarGraph(this.radarData[i], this.dataRatingsColors[i], this.dataRatingsGridColors[i]);
      }
    },
    drawRadarGraph(data, color, gridColor) {
      const ctx = this.context;
      let angle = -1.5715 + (6.286 / this.localNumberOfDataPoints / 2);
      for (let i = 0; i < this.localNumberOfDataPoints; i++) {
        this.lineAtAngle(ctx.canvas.width / 2, ctx.canvas.height / 2, 260 / 600 * ctx.canvas.height, angle);
        this.drawNumber(i + 1, angle);
        angle += 6.286 / this.localNumberOfDataPoints;
      }
      this.drawMiddleCircle();
      let radius = 50 / 600 * ctx.canvas.height + 20 / 600 * ctx.canvas.height;
      for (let i = 0; i < 10; i++) {
        this.drawOuter(radius);
        radius += 20 / 600 * ctx.canvas.height;
      }
      this.getPredefinedRatings(data, color);
      this.getRatings(data, gridColor);
    },
    lineAtAngle(x1, y1, length, angle) {
      const ctx = this.context;
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.strokeStyle = "#BBBBBB";
      ctx.lineTo(x1 + length * Math.cos(angle), y1 + length * Math.sin(angle));
      ctx.stroke();
    },
    drawMiddleCircle() {
      const ctx = this.context;
      ctx.beginPath();
      ctx.arc(ctx.canvas.width / 2, ctx.canvas.height / 2, 50 / 600 * ctx.canvas.height, 0, 2 * Math.PI, false);
      ctx.fillStyle = "white";
      ctx.fill();
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#BBBBBB";
      ctx.stroke();
    },
    drawOuter(radius) {
      const ctx = this.context;
      ctx.beginPath();
      ctx.arc(ctx.canvas.width / 2, ctx.canvas.height / 2, radius, 0, 2 * Math.PI, false);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#BBBBBB";
      ctx.stroke();
    },
    drawNumber(i, angle) {
  const ctx = this.context;
  ctx.beginPath();
  ctx.font = (20 / 600 * ctx.canvas.width) + "px Quicksand";
  ctx.fillStyle = "black";
  let x = ctx.canvas.width / 2 + 270 / 600 * ctx.canvas.width * Math.cos(angle);
  let y = ctx.canvas.height / 2 + 270 / 600 * ctx.canvas.height * Math.sin(angle);
  let legendIndex = i % this.legend.length;
  let legendText = this.legend[legendIndex];

  if (legendText === "Experience") {
    ctx.fillText(legendText, x-35, y+10);
  } else {
    ctx.fillText(legendText, x - 45, y);
  }
}
,
    drawRating(i, angle, color) {
      const ctx = this.context;
      let x = ctx.canvas.width / 2 + (50 / 600 * ctx.canvas.width + 20 / 600 * ctx.canvas.width * i) * Math.cos(angle);
      let y = ctx.canvas.height / 2 + (50 / 600 * ctx.canvas.height + 20 / 600 * ctx.canvas.height * i) * Math.sin(angle);
      ctx.beginPath();
      ctx.rect(x - 10 / 600 * ctx.canvas.width, y - 10 / 600 * ctx.canvas.height, 20 / 600 * ctx.canvas.width, 20 / 600 * ctx.canvas.height);
      ctx.fillStyle = color;
      ctx.fill();
    },
    drawRatingConnector(i, angle, i1, angle1, color) {
      const ctx = this.context;
      let x = ctx.canvas.width / 2 + (50 / 600 * ctx.canvas.width + 20 / 600 * ctx.canvas.width * i) * Math.cos(angle);
      let y = ctx.canvas.height / 2 + (50 / 600 * ctx.canvas.height + 20 / 600 * ctx.canvas.height * i) * Math.sin(angle);
      let x1 = ctx.canvas.width / 2 + (50 / 600 * ctx.canvas.width + 20 / 600 * ctx.canvas.width * i1) * Math.cos(angle1);
      let y1 = ctx.canvas.height / 2 + (50 / 600 * ctx.canvas.height + 20 / 600 * ctx.canvas.height * i1) * Math.sin(angle1);
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x1, y1);
      ctx.lineWidth = 2;
      ctx.strokeStyle = color;
      ctx.stroke();
    },
    drawRatingConnectorPlolygon(i, angle, i1, angle1, color) {
      const ctx = this.context;
      let x = ctx.canvas.width / 2 + (50 / 600 * ctx.canvas.width + 20 / 600 * ctx.canvas.width * i) * Math.cos(angle);
      let y = ctx.canvas.height / 2 + (50 / 600 * ctx.canvas.height + 20 / 600 * ctx.canvas.height * i) * Math.sin(angle);
      let x1 = ctx.canvas.width / 2 + (50 / 600 * ctx.canvas.width + 20 / 600 * ctx.canvas.width * i1) * Math.cos(angle1);
      let y1 = ctx.canvas.height / 2 + (50 / 600 * ctx.canvas.height + 20 / 600 * ctx.canvas.height * i1) * Math.sin(angle1);
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x1, y1);
      ctx.lineTo(ctx.canvas.width / 2, ctx.canvas.height / 2);
      ctx.lineWidth = 2;
      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
    },
    getRatings(data, color) {
      this.ratings = [];
      if (data && data.length > 0) {
        this.ratings = data;
      }
      let angle = -1.5715 + (6.286 / this.localNumberOfDataPoints / 2);
      for (let i = 0; i < this.ratings.length && i < this.localNumberOfDataPoints; i++) {
        this.drawRating(this.ratings[i], angle, color || "#c40d1e");
        if (i > 0) {
          this.drawRatingConnector(
            this.ratings[i - 1],
            angle - 6.286 / this.localNumberOfDataPoints,
            this.ratings[i],
            angle,
            color || "#c40d1e"
          );
          this.drawRatingConnectorPlolygon(
            this.ratings[i - 1],
            angle - 6.286 / this.localNumberOfDataPoints,
            this.ratings[i],
            angle,
            color || "rgba(196,11,30, 0.2)"
          );
        }
        if (i === this.localNumberOfDataPoints - 1) {
          this.drawRatingConnector(
            this.ratings[i],
            angle,
            this.ratings[0],
            -1.5715 + (6.286 / this.localNumberOfDataPoints / 2),
            color || "#c40d1e"
          );
          this.drawRatingConnectorPlolygon(
            this.ratings[i],
            angle,
            this.ratings[0],
            -1.5715 + (6.286 / this.localNumberOfDataPoints / 2),
            color || "rgba(196,11,30, 0.2)"
          );
        }
        angle += 6.286 / this.localNumberOfDataPoints;
      }
    },
    getPredefinedRatings(data, color) {
      if (data && data.length > 0) {
        let angle = -1.5715 + (6.286 / this.localNumberOfDataPoints / 2);
        for (let i = 0; i < data.length && i < this.localNumberOfDataPoints; i++) {
          this.drawRating(data[i], angle, color || "#F7A61B");
          if (i > 0) {
            this.drawRatingConnector(
              data[i - 1],
              angle - 6.286 / this.localNumberOfDataPoints,
              data[i],
              angle,
              color || "#F7A61B"
            );
            this.drawRatingConnectorPlolygon(
              data[i - 1],
              angle - 6.286 / this.localNumberOfDataPoints,
              data[i],
              angle,
              color || "rgba(247,166,27, 0.2)"
            );
          }
          if (i === this.localNumberOfDataPoints - 1) {
            this.drawRatingConnector(
              data[i],
              angle,
              data[0],
              -1.5715 + (6.286 / this.localNumberOfDataPoints / 2),
              color || "#F7A61B"
            );
            this.drawRatingConnectorPlolygon(
              data[i],
              angle,
              data[0],
              -1.5715 + (6.286 / this.localNumberOfDataPoints / 2),
              color || "rgba(247,166,27, 0.2)"
            );
          }
          angle += 6.286 / this.localNumberOfDataPoints;
        }
      }
    }
  },
  computed: {
    localNumberOfDataPoints() {
      return this.numberOfDataPoints || 10;
    }
  }
};
</script>
