<template>
<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" class="plus-icon" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H19" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 5L12 19" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
    name: 'PlusIcon',
}
</script>

<style scoped>
.plus-icon {
    cursor:pointer;
    position: absolute;
    width: 15px;
    height: 15px;
    color: rgb(0, 222, 11);
}
</style>