import { createRouter, createWebHistory } from 'vue-router'
import AboutUs from '../components/TopPages/AboutUs.vue'
import ChiSiamo from '../components/TopPages/DescriptionUs.vue'
import CasiUso from '../components/TopPages/Cases.vue'
import PricingPage from '../components/TopPages/Pricing.vue'
import MainPage from '../components/Main.vue'
import StartInputBar from '../components/screens/Posizionamento/StartInputBar.vue';
import ExperiencePage from '../components/screens/Posizionamento/ExperienceConfirm.vue';
import CommunityPage from '../components/screens/Posizionamento/CommunityConfirm.vue';
import Matrix from '../components/screens/Posizionamento/Matrix.vue'; // togliere
import LogIn from '@/components/TopPages/LogIn.vue'
import SignIn from '@/components/TopPages/SignIn.vue'
import UserPage from '@/components/User/User.vue'
import Sessions from '@/components/TopPages/SessionsPage.vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
//import FinalPage from '../components/screens/Posizionamento/FinalPage.vue'; // cambiare


const routes = [
  {
    path: '/',
    name: 'home',
    component: MainPage
  },
  {
    path: '/prezzo',
    name: 'pricing',
    component: PricingPage
  },
  {
    path: '/noi',
    name: 'diconodinoi',
    component:  AboutUs   
  },
  {
    path: '/casi-d-uso',
    name: 'casiduso',
    component: CasiUso
  },
  {
    path: '/chi-siamo',
    name: 'chisiamo',
    component: ChiSiamo
  },
  {
    path: '/main',
    name: 'main',
    component: MainPage
  },
  {
    path: '/product/matrix',
    name: 'matrix',
    component: Matrix
  },
  {
    path: '/about',
    name: 'about',
    component: MainPage
  },
  {
      path: '/login',
      name: 'login',
      component: LogIn
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignIn
    },
  {
    path: '/product',
    name: 'product',
    component: StartInputBar,
    meta: { requiresAuth: true }
  },
  {
    path: '/product/experience',
    name: 'Experience',
    component: ExperiencePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/product/community',
    name: 'Community',
    component: CommunityPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/user',
    name: 'User',
    component: UserPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/sessioni',
    name: 'Sessioni',
    component: Sessions,
    meta: { requiresAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    )
  })
};

router.beforeEach(async (to, from, next) => {
  const auth = await getCurrentUser();
  console.log(`Navigating to: ${to.path}`);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log('This route requires authentication');
    if (auth) {
      console.log('User is authenticated');
      console.log(auth)
      next();
    } else {
      console.log('User is not authenticated');
      alert("Non hai l'accesso!");
      next('/login');
    }
  } else {
    console.log('This route does not require authentication');
    next();
  }
});

export default router
